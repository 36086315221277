import React from 'react';
import { Stack } from '@moda/om';
import { useDesktopNavigator } from '../../hooks/useNavigator';
import { DesktopSiteNavItemLoading } from '../SiteNav/DesktopSiteNav/DesktopSiteNavItemLoading';
import { throwQueryError } from '../../lib/apollo/throwQueryError';
import { useShowHolidayHomeLinkFeatureFlag } from '../../hooks/useHolidayLinkFeatureFlag';
import { DesktopNavigationV2Vertical } from './DesktopNavigationV2Vertical';

export const DesktopNavigationV2 = () => {
  const { data, loading, error } = useDesktopNavigator();

  const showHolidayHomeLink = useShowHolidayHomeLinkFeatureFlag();

  if (error) throwQueryError(error);

  if (loading) return <DesktopSiteNavItemLoading />;

  if (!data) return null;

  return (
    <Stack direction="horizontal" space={5} className="DesktopNavigationV2">
      {data
        .filter(vertical => {
          if (vertical.title.toLowerCase() === 'holiday') {
            return showHolidayHomeLink;
          }
          return true;
        })
        .map(vertical => (
          <DesktopNavigationV2Vertical key={vertical.id} vertical={vertical} />
        ))}
    </Stack>
  );
};
