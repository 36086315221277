import { useCallback } from 'react';
import { authenticate as rawAuthenticate, AuthParams } from '../../lib/authenticate';

export const useAuthenticate = () => {
  const twoFactorAuthLoginEnabled = false;

  const authenticate = useCallback(
    (authParams: Omit<AuthParams, 'version'>) =>
      rawAuthenticate({ ...authParams, twoFactorAuthLoginEnabled }),
    [twoFactorAuthLoginEnabled]
  );

  return { authenticate };
};
