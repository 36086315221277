import React from 'react';
import classNames from 'classnames';
import { Text } from '@moda/om';
import { Link } from 'react-router-dom';
import { ResponsiveImage } from '../../ResponsiveImage';
import { EditorialNavigatorUnitFragment } from '../../../generated/types';
import { DesktopNavigationV2EditorialColumnText } from './DesktopNavigationV2EditorialColumnText';

import './DesktopNavigationV2EditorialColumn.scss';

interface Props {
  className?: string;
  content: EditorialNavigatorUnitFragment;
  onClick: () => void;
}

export const DesktopNavigationV2EditorialColumn: React.FC<Props> = ({
  className,
  content: { imageSrc, imageAlt, href, headline, anchorText },
  onClick
}) => (
  <div className={classNames('DesktopNavigationV2EditorialColumn', className)}>
    <Link className="DesktopNavigationV2EditorialColumn__anchor" to={href || ''} onClick={onClick}>
      {headline && (
        <Text treatment="eyebrow" className="DesktopNavigationV2EditorialColumn__header">
          {headline}
        </Text>
      )}

      {!headline && <div className="DesktopNavigationV2EditorialColumn__header-placeholder" />}

      {imageSrc && (
        <ResponsiveImage
          className="DesktopNavigationV2EditorialColumn__image"
          src={imageSrc}
          maxWidth={160}
          maxHeight={260}
          alt={imageAlt || 'Editorial Image'}
        />
      )}

      {anchorText && <DesktopNavigationV2EditorialColumnText text={anchorText} />}
    </Link>
  </div>
);
