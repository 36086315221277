import { gql } from '@apollo/client';
import React from 'react';
import { Breakpoint } from '@moda/om';
import { useFeatureFlag } from '@moda/portal-stanchions';
import { SiteNavFragment } from '../../generated/types';
import { MobileNavV2 } from '../MobileNavV2';
import { DesktopNavigationV2Provider } from '../DesktopNavigationV2/DesktopNavigationV2Provider';
import { DesktopSiteNav, DESKTOP_SITE_NAV_FRAGMENT } from './DesktopSiteNav';
import { MobileSiteNav } from './MobileSiteNav';

import './SiteNav.scss';

export const SITE_NAV_FRAGMENT = gql`
  fragment SiteNavFragment on DisplayPage {
    ...DesktopSiteNavFragment
  }
  ${DESKTOP_SITE_NAV_FRAGMENT}
`;

interface Props {
  displayPage?: SiteNavFragment | null;
}

export const useMobileNavV2FeatureFlag = () =>
  useFeatureFlag('mobile-nav-v2', [{ default: false }, { if: true, then: true }]);

export const SiteNav: React.FC<Props> = ({ displayPage }) => {
  const isMobileNavV2Enabled = useMobileNavV2FeatureFlag();

  return (
    <div className="SiteNav">
      <Breakpoint gt="md">
        <DesktopNavigationV2Provider>
          <DesktopSiteNav displayPage={displayPage} />
        </DesktopNavigationV2Provider>
      </Breakpoint>

      <Breakpoint lt="md">
        {!isMobileNavV2Enabled && <MobileSiteNav />}

        {isMobileNavV2Enabled && <MobileNavV2 />}
      </Breakpoint>
    </div>
  );
};
