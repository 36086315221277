import { ApolloError } from '@apollo/client';
import { JsonApiError } from '../constants';
import { GenericError } from '../lib/error';

type UserApiApolloError =
  | (ApolloError & {
      networkError: Error & JsonApiError;
    })
  | null;

export type UserApiMutationError = Record<'network' | string, string>;

export const createUserApiError = (error?: GenericError) => {
  if (!error) {
    return undefined;
  }

  const mutationError: UserApiMutationError = {};
  const errorResults = (error as UserApiApolloError)?.networkError?.result?.errors;

  if (errorResults) {
    errorResults.forEach(error => {
      mutationError[error.field ? error.field : 'network'] = Array.isArray(error.detail)
        ? error.detail[0]
        : error.detail;
    });
  }

  return mutationError;
};
