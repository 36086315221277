import { useEffect, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { always, omit, tryCatch } from 'ramda';
import { QueryParameters, parseQueryString, generateQueryString } from '@moda/portal-stanchions';
import { useCookies, getCookies } from './useCookies';

const STORE_KEY = 'sessionQueryParams';

export const useSessionQueryParams = () => {
  const { cookies, setCookie, deleteCookie } = useCookies();
  const history = useHistory();
  const location = useLocation();
  const queryParams = useMemo(() => parseQueryString(location.search), [location.search]);

  const parseSafely = tryCatch(JSON.parse, always({}));

  const params: QueryParameters = useMemo(
    () => ({ ...parseSafely(cookies.sessionQueryParams ?? '{}'), ...queryParams }),
    [cookies.sessionQueryParams, queryParams, parseSafely]
  );

  const removeParameter = useCallback(
    (key: string) => {
      const storedParams = parseSafely(getCookies().sessionQueryParams ?? '{}');
      setCookie(STORE_KEY, JSON.stringify(omit([key], storedParams)));

      if (queryParams[key]) {
        const queryString = generateQueryString(omit([key], queryParams));
        history.replace(`${location.pathname}${queryString ? '?' : ''}${queryString}`);
      }
    },
    [history, location.pathname, queryParams, setCookie, parseSafely]
  );

  useEffect(() => {
    const storedParams = parseSafely(getCookies().sessionQueryParams ?? '{}');
    if (Object.keys(queryParams).length) {
      setCookie(STORE_KEY, JSON.stringify({ ...storedParams, ...queryParams }));
    }
  }, [queryParams, setCookie, deleteCookie, parseSafely]);

  return { params, removeParameter };
};
