import React, { useCallback } from 'react';
import { Text, TextColor } from '@moda/om';
import classNames from 'classnames';
import { DesktopNavigatorVerticalFragment } from '../../../generated/types';
import { tc } from '../../../lib/trackingContext';
import { Link } from '../../Link';
import { useDesktopNavigationV2Context } from '../DesktopNavigationV2Provider';

import './DesktopNavigationV2Vertical.scss';

type Props = {
  vertical: DesktopNavigatorVerticalFragment;
};

export const DesktopNavigationV2Vertical: React.FC<Props> = ({ vertical }) => {
  const { selectedVerticalId, handleChangeSelectedVertical } = useDesktopNavigationV2Context();

  const isSelected = selectedVerticalId === vertical.id;

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (isSelected) return;

      if (!handleChangeSelectedVertical?.(vertical.id)) return;

      tc.track('Navigation Item Clicked', {
        addToTrackingEvent: {
          url: vertical.href,
          text: vertical.title,
          label: vertical.title,
          hierarchy: vertical.title,
          coordinateX: event?.pageX || 0,
          coordinateY: event?.pageY || 0
        }
      });
    },
    [handleChangeSelectedVertical, isSelected, vertical.href, vertical.id, vertical.title]
  );

  return (
    <Link
      className={classNames('DesktopNavigationV2Vertical', {
        'DesktopNavigationV2Vertical--selected': isSelected,
        'DesktopNavigationV2Vertical--unselected': !isSelected
      })}
      data-testid="DesktopNavigationV2Vertical"
      to={vertical.href}
      onClick={handleClick}
    >
      <Text
        className="DesktopNavigationV2Vertical__title"
        color={(vertical.style.color as TextColor) || 'ink'}
        data-testid="DesktopNavigationV2Vertical__title"
        treatment={isSelected || vertical.style.bold ? 'bold1' : undefined}
      >
        {vertical.title}
      </Text>
    </Link>
  );
};
