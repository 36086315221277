import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { queryParamToString, useQueryParams } from '@moda/portal-stanchions';
import { ApolloError } from '@apollo/client';
import { useCurrentRouteParams } from '../../../hooks/useCurrentRouteParams';
import { useDesktopNavigator } from '../../../hooks/useNavigator';
import { DesktopNavigatorVerticalFragment } from '../../../generated/types';
import { useDesktopNavigationV2FeatureFlag } from '../../../hooks/useDesktopNavigationV2FeatureFlag';

type VerticalId = string;

type TDesktopNavigationV2Context = {
  verticals?: DesktopNavigatorVerticalFragment[];
  selectedVerticalId?: VerticalId;
  selectedVerticalData?: DesktopNavigatorVerticalFragment;
  handleChangeSelectedVertical?: (verticalId: VerticalId) => void;
  loading: boolean;
  error?: ApolloError;
};

const DesktopNavigationV2Context = createContext<TDesktopNavigationV2Context>({
  verticals: undefined,
  selectedVerticalId: undefined,
  selectedVerticalData: undefined,
  handleChangeSelectedVertical: undefined,
  loading: false,
  error: undefined
});

interface Props {
  children: React.ReactNode;
}

export const DesktopNavigationV2Provider: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const { queryParams } = useQueryParams();
  const params = useCurrentRouteParams<{ vertical?: string }>();
  const { data: verticals, loading, error } = useDesktopNavigator();
  const [selectedVerticalId, setSelectedVerticalId] = useState<undefined | VerticalId>();
  const isDesktopSiteNavV2Enabled = useDesktopNavigationV2FeatureFlag();

  const isVerticalIdValid = useCallback(
    (verticalId: VerticalId) => {
      if (!verticals) return false;

      if (!verticals.find(vertical => vertical.id === verticalId)) {
        return false;
      }

      return true;
    },
    [verticals]
  );

  const handleChangeSelectedVertical = useCallback(
    (verticalId: VerticalId) => {
      if (!verticalId) return;

      if (!isVerticalIdValid(verticalId)) throw new Error('Incorrect vertical id.');

      setSelectedVerticalId(verticalId);
    },
    [isVerticalIdValid]
  );

  useEffect(() => {
    if (selectedVerticalId) return;

    const idFromPathname = verticals?.find(vertical => vertical.href === pathname)?.id;

    if (idFromPathname && isVerticalIdValid(idFromPathname)) {
      handleChangeSelectedVertical(idFromPathname);

      return;
    }

    const idFromVerticalSlug = verticals?.find(
      ({ title }) =>
        title === params?.vertical || title === queryParamToString(queryParams.vertical)
    )?.id;

    if (idFromVerticalSlug && isVerticalIdValid(idFromVerticalSlug)) {
      handleChangeSelectedVertical(idFromVerticalSlug);

      return;
    }

    const idFromEditorialData = verticals?.find(vertical =>
      vertical.items.some(item =>
        item.units.some(unit =>
          unit.__typename === 'EditorialNavigatorUnit'
            ? unit.href === pathname
            : unit.subcategories.some(subcategory => subcategory.href === pathname)
        )
      )
    )?.id;

    if (idFromEditorialData && isVerticalIdValid(idFromEditorialData)) {
      handleChangeSelectedVertical(idFromEditorialData);

      return;
    }

    const idFromBaseVerticalHref = verticals?.find(vertical => vertical.href === '/')?.id;

    if (idFromBaseVerticalHref && isVerticalIdValid(idFromBaseVerticalHref)) {
      handleChangeSelectedVertical(idFromBaseVerticalHref);

      return;
    }

    const idFromFirstVertical = verticals?.[0]?.id;

    if (idFromFirstVertical && isVerticalIdValid(idFromFirstVertical)) {
      handleChangeSelectedVertical(idFromFirstVertical);

      return;
    }
  }, [
    handleChangeSelectedVertical,
    isVerticalIdValid,
    params?.vertical,
    pathname,
    queryParams.vertical,
    selectedVerticalId,
    verticals
  ]);

  const selectedVerticalData = useMemo(() => {
    if (!verticals) return;

    return verticals.find(vertical => vertical.id === selectedVerticalId);
  }, [selectedVerticalId, verticals]);

  return (
    <DesktopNavigationV2Context.Provider
      value={
        isDesktopSiteNavV2Enabled
          ? {
              selectedVerticalId,
              selectedVerticalData,
              handleChangeSelectedVertical,
              loading,
              verticals,
              error
            }
          : { loading: false }
      }
    >
      {children}
    </DesktopNavigationV2Context.Provider>
  );
};

export const useDesktopNavigationV2Context = () => useContext(DesktopNavigationV2Context);
