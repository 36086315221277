import React from 'react';
import { Constrain, Divider, Stack } from '@moda/om';
import { SiteFooterMailingList } from '../SiteFooterMailingList';
import { SiteFooterBasement } from '../SiteFooterBasement';
import { SiteFooterLinks } from '../SiteFooterLinks';
import { useDesktopNavigator } from '../../../hooks/useNavigator';

import './SiteFooterDesktop.scss';

export const SiteFooterDesktop = () => {
  const { isDesktopSiteNavV2Enabled } = useDesktopNavigator();

  return (
    <Stack space={5}>
      <Constrain className="SiteFooterDesktop">
        <Stack space={5}>
          <SiteFooterMailingList className="SiteFooterDesktop__mailing-list" />
          <SiteFooterLinks className="SiteFooterDesktop__links" />
          {!isDesktopSiteNavV2Enabled && <Divider className="SiteFooterDesktop__divider" />}
        </Stack>
      </Constrain>
      {isDesktopSiteNavV2Enabled && <Divider className="SiteFooterDesktop__divider--refresh" />}
      <Constrain>
        <SiteFooterBasement />
      </Constrain>
    </Stack>
  );
};
