import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { Credentials } from '../TwoFactorAuthenticationProvider';
import { RESEND_OTP_CODE } from './useResendCode.rest';

export const useResendCode = () => {
  const [mutate, { loading, error }] = useMutation(RESEND_OTP_CODE);

  const resend = useCallback(
    (credentials: Credentials) => {
      if (!credentials) return;

      const { email, password } = credentials;

      return mutate({
        variables: {
          input: {
            data: {
              type: 'resend_otp',
              attributes: {
                email,
                password
              }
            }
          }
        }
      });
    },
    [mutate]
  );

  return {
    resend,
    loading,
    error
  };
};
