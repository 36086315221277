import { Text, TextColor } from '@moda/om';
import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import { DesktopNavigatorVerticalItemFragment } from '../../../generated/types';
import { tc } from '../../../lib/trackingContext';
import { Link } from '../../Link';
import { useDesktopNavigationV2Context } from '../DesktopNavigationV2Provider';

import './DesktopNavigationV2MainContentCategory.scss';

type Props = {
  category: DesktopNavigatorVerticalItemFragment;
  selected: boolean;
  onSelect: () => void;
  onClickCategory: () => void;
};

const DESKTOP_NAVIGATION_DELAY_IN_MS = 75;

export const DesktopNavigationV2MainContentCategory: React.FC<Props> = ({
  category,
  selected: isSelected,
  onSelect,
  onClickCategory
}) => {
  const { selectedVerticalData } = useDesktopNavigationV2Context();
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    hoverTimeout.current = setTimeout(() => {
      onSelect();
    }, DESKTOP_NAVIGATION_DELAY_IN_MS);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
      hoverTimeout.current = null;
    }
  };

  const handleTracking = useCallback(
    ({ pageX: coordinateX, pageY: coordinateY }: React.MouseEvent) =>
      tc.track('Navigation Item Clicked', {
        addToTrackingEvent: {
          url: category.href,
          text: category.name,
          label: category.name,
          hierarchy: `${selectedVerticalData?.title}/${category.name}`,
          coordinateX,
          coordinateY
        }
      }),
    [category.href, category.name, selectedVerticalData?.title]
  );

  return (
    <Link
      className={classNames('DesktopNavigationV2MainContentCategory', {
        'DesktopNavigationV2MainContentCategory--selected': isSelected,
        'DesktopNavigationV2MainContentCategory--unselected': !isSelected
      })}
      data-testid="DesktopNavigationV2MainContentCategory"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(event: React.MouseEvent) => {
        onClickCategory();
        handleTracking(event);
      }}
      to={category.href}
    >
      <Text
        treatment={category.style.bold ? 'bold1' : undefined}
        color={(category.style.color as TextColor) || 'ink'}
      >
        {category.name}
      </Text>
    </Link>
  );
};
