import { Divider, Stack } from '@moda/om';
import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { DesktopNavigationV2MainContentCategory } from '../DesktopNavigationV2MainContentCategory';
import { DesktopNavigationV2Pulldown } from '../DesktopNavigationV2Pulldown';
import { DesktopSiteNavItemLoading } from '../../SiteNav/DesktopSiteNav/DesktopSiteNavItemLoading';
import { useDesktopNavigationV2Context } from '../DesktopNavigationV2Provider';

import './DesktopNavigationV2MainContent.scss';

export const DesktopNavigationV2MainContent = () => {
  const { selectedVerticalData, selectedVerticalId, verticals } = useDesktopNavigationV2Context();

  const [activeCategory, setActiveCategory] = useState<string>();

  const pulldownContent = useMemo(() => {
    if (!selectedVerticalData) return;

    if (!selectedVerticalId) return;

    if (!activeCategory) return;

    const content = selectedVerticalData.items.find(item => item.id === activeCategory);

    if (!content) return;

    if (!content.units.length) return;

    return content;
  }, [activeCategory, selectedVerticalData, selectedVerticalId]);

  if (!verticals) return;

  return verticals.map(verticalData => (
    <div
      className={classNames('DesktopNavigationV2MainContent', {
        'DesktopNavigationV2MainContent--visible': verticalData.id === selectedVerticalId
      })}
      data-testid="DesktopNavigationV2MainContent"
      key={verticalData.id}
      onMouseLeave={() => setActiveCategory(undefined)}
    >
      <Stack className="DesktopNavigationV2MainContent__items" space={3}>
        <Divider className="DesktopNavigationV2MainContent__divider" color="klein-blue" />

        {!verticalData && <DesktopSiteNavItemLoading />}

        {verticalData && (
          <Stack
            direction="horizontal"
            className="DesktopNavigationV2MainContent__item"
            justifyContent="center"
            space={5}
          >
            {verticalData.items.map(item => (
              <DesktopNavigationV2MainContentCategory
                key={item.id}
                category={item}
                selected={activeCategory === item.id}
                onSelect={() => setActiveCategory(item.id)}
                onClickCategory={() => setActiveCategory(undefined)}
              />
            ))}
          </Stack>
        )}
      </Stack>

      {pulldownContent && (
        <DesktopNavigationV2Pulldown
          content={pulldownContent}
          onHide={() => setActiveCategory(undefined)}
        />
      )}
    </div>
  ));
};
