import { gql, TypedDocumentNode } from '@apollo/client';
import { AuthResponseSuccess } from '../../../../../lib/authenticate';

export enum TwoFactorAuthMethod {
  TextMessage = 'sms',
  AuthApp = 'app'
}

type VerifyOneTimePasswordMutationInputData = {
  type: 'two_factor_auth';
  attributes: {
    email: string | null;
    password?: string;
    otp_code?: string;
    two_factor_auth_method: TwoFactorAuthMethod;
  };
};

type VerifyOneTimePasswordMutationInput = {
  data: VerifyOneTimePasswordMutationInputData;
};

export type VerifyOneTimePasswordMutation = {
  verify: AuthResponseSuccess & {
    __typename: 'VerifyOneTimePasswordPayload';
  };
};

type VerifyOneTimePasswordMutationVariables = {
  input: VerifyOneTimePasswordMutationInput;
};

export const VERIFY_ONE_TIME_PASSWORD_MUTATION: TypedDocumentNode<
  VerifyOneTimePasswordMutation,
  VerifyOneTimePasswordMutationVariables
> = gql`
  mutation VerifyOneTimePasswordMutation($input: VerifyOneTimePasswordMutationInput) {
    verify(input: $input)
      @rest(
        type: "VerifyOneTimePasswordPayload"
        endpoint: "user"
        method: "POST"
        path: "/v2/verify_otp"
      ) {
      data {
        id
        type
        attributes {
          cart_id
          client_tier
          country_code
          email
          first_name
          has_password
          is_amazon_connected
          is_facebook_connected
          is_member
          last_name
          phone
          status_level
          user_loyalty_status
        }
      }
      meta {
        access_token
      }
    }
  }
`;
