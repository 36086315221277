import loadable from '@loadable/component';
import { noop } from '@moda/portal-stanchions';
import React, { useCallback, useState, createContext } from 'react';
import { UserApiMutationError } from '../../../lib/createUserApiError';
import { TwoFactorAuthMethod } from '../../AccountSettings/AccountSettingsTwoFactorAuthentication/AccountSettingsTwoFactorAuthenticationEdit/useVerifyOneTimePassword/verifyOneTimePassword.rest';
import { Loading } from '../../Loading';
import { useResendCode } from './useResendCode';

const TwoFactorAuthenticationCodeVerificationModal = loadable(
  () => import('../../TwoFactorAuthenticationCodeVerificationModal'),
  {
    fallback: <Loading />,
    resolveComponent: exports => exports.TwoFactorAuthenticationCodeVerificationModal
  }
);

export type Credentials = {
  email: string;
  password: string;
};

export type OtpCode = string;

type OnSuccessCallback = (code: OtpCode) => void;

export const TwoFactorAuthenticationContext = createContext({
  openModal: (mode: TwoFactorAuthMethod) => new Promise<OtpCode>(() => mode),
  callback: undefined as undefined | OnSuccessCallback,
  error: undefined as undefined | UserApiMutationError,
  setError: noop as (error: UserApiMutationError | undefined) => void,
  setPhoneNumber: noop as (phoneNumber: string) => void,
  loading: false,
  setLoading: noop as (state: boolean) => void,
  closeModal: noop,
  chooseApp: noop,
  chooseSMS: noop,
  mode: undefined as undefined | TwoFactorAuthMethod,
  phoneNumber: undefined as undefined | string,
  setCredentials: noop as (credentials: Credentials | undefined) => void,
  handleResendCode: noop as () => void
});

export const TwoFactorAuthenticationProvider: React.FC<{ children?: React.ReactNode }> = ({
  children
}) => {
  const enabled = false;
  const [callback, setCallback] = useState<undefined | OnSuccessCallback>(undefined);
  const [error, setError] = useState<undefined | UserApiMutationError>(undefined);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<undefined | string>(undefined);
  const [credentials, setCredentials] = useState<undefined | Credentials>(undefined);
  const [mode, setMode] = useState<TwoFactorAuthMethod | undefined>(undefined);
  const { resend } = useResendCode();

  const openModal = useCallback(
    (mode: TwoFactorAuthMethod) =>
      new Promise<OtpCode>(resolve => {
        setCallback(() => resolve);

        setMode(mode);
      }),
    []
  );

  const closeModal = useCallback(() => {
    setCallback(undefined);

    setCredentials(undefined);

    setMode(undefined);
  }, []);

  const handleResendCode = useCallback(() => {
    if (!credentials) return;

    return resend(credentials);
  }, [credentials, resend]);

  const chooseApp = useCallback(() => setMode(TwoFactorAuthMethod.AuthApp), []);

  const chooseSMS = useCallback(() => setMode(TwoFactorAuthMethod.TextMessage), []);

  if (!enabled) return <>{children}</>;

  return (
    <TwoFactorAuthenticationContext.Provider
      value={{
        mode,
        openModal,
        closeModal,
        chooseApp,
        chooseSMS,
        callback,
        error,
        setError,
        loading,
        setLoading,
        phoneNumber,
        setPhoneNumber,
        setCredentials,
        handleResendCode
      }}
    >
      {children}

      <TwoFactorAuthenticationCodeVerificationModal />
    </TwoFactorAuthenticationContext.Provider>
  );
};
