import { StatusCodes } from 'http-status-codes';
import { UseUserQuery } from '../generated/types';
import { USER_API_URL } from '../constants';
import { TwoFactorAuthMethod } from '../components/AccountSettings/AccountSettingsTwoFactorAuthentication/AccountSettingsTwoFactorAuthenticationEdit/useVerifyOneTimePassword/verifyOneTimePassword.rest';
import { tc } from './trackingContext';

type UseUserQueryUser = NonNullable<UseUserQuery['user']>;

export enum AuthAction {
  Register = 'REGISTER',
  SignIn = 'SIGN IN'
}

export type AuthAttributes = {
  access_token?: string;
  email?: string;
  link_accounts?: boolean;
  password?: string;
  provider?: string;
  recaptcha?: AuthRecaptcha;
  cart_id?: string | null;
  guest?: boolean;
  first_name?: string;
  last_name?: string;
  opted_in?: boolean;
  user_loyalty_status?: number;
  status_level?: number;
};

export type AuthError = {
  code: string;
  detail?: string | string[];
  message?: string;
};

export type AuthErrorMessage = string;

type AuthErrorMessages = {
  [errorCode: string]: AuthErrorMessage;
};

type AuthMeta = {
  access_token: string;
};

export type AuthParams = {
  action: AuthAction;
  attributes: AuthAttributes;
  bypassRackAttack?: string;
  twoFactorAuthLoginEnabled?: boolean;
  emailVerificationEnabled?: boolean;
};

export type AuthPayloadSuccess = Omit<UseUserQueryUser, '__typename' | 'isLoggedIn' | 'oauth'> & {
  cartId: number | null;
};
export type AuthRecaptcha = {
  token: string;
  type: 'v2' | 'v3';
};
type AuthRequestBody = {
  data: {
    attributes: AuthAttributes;
    type: string;
  };
};
type AuthRequestConfig = {
  path: string;
  type: string;
};
type AuthRequestConfigs = {
  [action: string]: AuthRequestConfig;
};

export type AuthResponse =
  | AuthResponseSuccess
  | AuthResponseTwoFactorRequired
  | AuthResponseEmailVerificationRequired
  | AuthResponseError;

export type AuthResponseTwoFactorRequired = {
  data: {
    attributes: {
      app_2fa_enabled: boolean;
      email: string;
      first_name: string;
      last_name: string;
      sms_2fa_enabled: boolean;
      two_factor_auth_phone: string;
    };
    type: 'user';
  };
};

export type AuthResponseEmailVerificationRequired = {
  data: {
    type: 'unverified_user';
    attributes: {
      email: string;
      first_name: string;
      last_name: string;
    };
  };
};

export type AuthResponseSuccess = {
  data: {
    attributes: {
      affiliate?: number | null;
      cart_id?: number | null;
      client_tier?: number | null;
      country_code?: string;
      email: string;
      first_name?: string;
      has_password: boolean;
      is_amazon_connected?: boolean;
      is_member: boolean;
      last_name?: string;
      phone?: string | null;
      status_level?: number;
      user_loyalty_status?: number;
      sms_2fa_enabled?: boolean;
      app_2fa_enabled?: boolean;
    };
    id: string;
    type: 'user';
  };
  meta: AuthMeta;
};

type AuthResponseError = {
  errors: AuthError[] | [];
};

type AuthHeaders = {
  [header: string]: string;
};

export const isAuthResponseSuccess = (response: AuthResponse): response is AuthResponseSuccess =>
  'meta' in response && 'data' in response;

export const isAuthResponseError = (response: AuthResponse): response is AuthResponseError =>
  'errors' in response && response.errors.length > 0;

export const isAuthResponseTwoFactorRequired = (
  response: AuthResponse
): response is AuthResponseTwoFactorRequired =>
  'data' in response &&
  ((response as AuthResponseTwoFactorRequired).data.attributes.sms_2fa_enabled ||
    (response as AuthResponseTwoFactorRequired).data.attributes.app_2fa_enabled);

export const isAuthResponseEmailVerificationRequired = (
  response: AuthResponse
): response is AuthResponseEmailVerificationRequired =>
  'data' in response && response.data.type === 'unverified_user';

export const ERROR_MESSAGES: AuthErrorMessages = {
  account_exists: 'An account with that email already exists.',
  invalid_credentials: 'Email and password do not match.',
  recaptcha_failed: 'Try again in 30 seconds.',
  default: 'Something went wrong.'
};

const AUTH_REQUEST_CONFIGS: AuthRequestConfigs = {
  REGISTER: {
    path: '/users',
    type: 'user'
  },
  'SIGN IN': {
    path: '/login',
    type: 'login'
  }
};

const getHeaders = (bypassRackAttack?: string): AuthHeaders => {
  const headers: AuthHeaders = { 'Content-Type': 'application/vnd.api+json' };

  if (bypassRackAttack) {
    headers['X_BYPASS_RACK_ATTACK'] = bypassRackAttack;
  }

  return headers;
};

export const handleAuthSuccess = (response: AuthResponseSuccess): AuthPayloadSuccess => ({
  affiliate: response.data.attributes.affiliate ?? null,
  accessToken: response.meta.access_token,
  cartId: response.data.attributes.cart_id ?? null,
  clientTier: response.data.attributes.client_tier ?? null,
  countryCode: response.data.attributes.country_code ?? null,
  email: response.data.attributes.email,
  firstName: response.data.attributes.first_name ?? null,
  hasPassword: response.data.attributes.has_password ?? null,
  id: response.data.id,
  isAmazonConnected: response.data.attributes.is_amazon_connected ?? null,
  isMember: response.data.attributes.is_member ?? null,
  lastName: response.data.attributes.last_name ?? null,
  phone: response.data.attributes.phone ?? null,
  statusLevel: response.data.attributes.status_level ?? null,
  userLoyaltyStatus: response.data.attributes.user_loyalty_status ?? null,
  twoFactorAuthTextMessageEnabled: response.data.attributes.sms_2fa_enabled ?? false,
  twoFactorAuthAppEnabled: response.data.attributes.app_2fa_enabled ?? false
});

export type AuthPayloadTwoFactorRequired =
  | { method: TwoFactorAuthMethod.TextMessage; twoFactorAuthPhoneNumber: string }
  | { method: TwoFactorAuthMethod.AuthApp };

export type AuthPayloadEmailVerificationRequired = { emailVerificationRequired: true };

export type AuthPayload =
  | AuthPayloadSuccess
  | AuthPayloadTwoFactorRequired
  | AuthPayloadEmailVerificationRequired;

export const isAuthPayloadSuccess = (payload: AuthPayload): payload is AuthPayloadSuccess =>
  'accessToken' in payload;

export const isAuthPayloadTwoFactorRequired = (
  payload: AuthPayload
): payload is AuthPayloadTwoFactorRequired => 'method' in payload;

export const isAuthPayloadEmailVerificationRequired = (
  payload: AuthPayload
): payload is AuthPayloadEmailVerificationRequired => 'emailVerificationRequired' in payload;

const handleTwoFactorAuthResponse = ({
  data: {
    attributes: {
      sms_2fa_enabled: twoFactorAuthSmsEnabled,
      two_factor_auth_phone: twoFactorAuthPhoneNumber
    }
  }
}: AuthResponseTwoFactorRequired): AuthPayloadTwoFactorRequired => {
  if (twoFactorAuthSmsEnabled) {
    return { method: TwoFactorAuthMethod.TextMessage, twoFactorAuthPhoneNumber };
  }

  return { method: TwoFactorAuthMethod.AuthApp };
};

const handleEmailVerificationRequiredResponse = (): AuthPayloadEmailVerificationRequired => ({
  emailVerificationRequired: true
});

const trackInvalidCredentials = () => {
  tc.track('Sign-In Failed', {
    addToTrackingEvent: { label: 'Sign-In Failed' }
  });
};

const trackAccountDisabled = () => {
  tc.track('Sign-In Failed and Account Disabled', {
    addToTrackingEvent: {
      label: 'Sign-In Failed and Account Disabled'
    }
  });
};

const trackRecaptchaFailed = () => {
  tc.track('Sign-In Blocked by Captcha', {
    addToTrackingEvent: {
      label: 'Sign-In Blocked by Captcha'
    }
  });
};

const toString = (item?: string | string[]) => (Array.isArray(item) ? item[0] : item);

export const handleAuthError = (error: AuthError) => {
  error.message = ERROR_MESSAGES[error.code] || toString(error.detail) || ERROR_MESSAGES.default;

  if (error.detail === 'Account Locked. Please reset password to continue.') trackAccountDisabled();

  if (error.message === 'Email and password do not match.') trackInvalidCredentials();

  if (error.code === 'recaptcha_failed') trackRecaptchaFailed();

  throw error;
};

export const authenticate = ({
  action,
  attributes,
  bypassRackAttack,
  twoFactorAuthLoginEnabled,
  emailVerificationEnabled
}: AuthParams): Promise<AuthPayload> => {
  const { path, type } = AUTH_REQUEST_CONFIGS[action];

  const version = (() => {
    if (action === AuthAction.Register) {
      return emailVerificationEnabled && !attributes.provider && !attributes.guest ? 'v2' : 'v1';
    }

    if (!twoFactorAuthLoginEnabled) {
      return 'v1';
    }

    return 'v2';
  })();

  const body: AuthRequestBody = {
    data: {
      attributes,
      type
    }
  };

  return fetch(`${USER_API_URL}/${version}${path}`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: getHeaders(bypassRackAttack),
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(body)
  })
    .then(response => {
      if (response.status === StatusCodes.TOO_MANY_REQUESTS) {
        throw new Error('Try again in 30 seconds.');
      }

      return response.json();
    })
    .then((response: AuthResponse) => {
      if (isAuthResponseTwoFactorRequired(response)) return handleTwoFactorAuthResponse(response);

      if (isAuthResponseEmailVerificationRequired(response))
        return handleEmailVerificationRequiredResponse();

      if (isAuthResponseSuccess(response)) return handleAuthSuccess(response);

      if (isAuthResponseError(response)) return handleAuthError(response.errors[0]);

      throw new Error(ERROR_MESSAGES.default);
    });
};
