import { parseQueryString } from '@moda/portal-stanchions';
import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

export const usePreserveUtmQueryParam = () => {
  const location = useLocation();
  const { replace } = useHistory();

  useEffect(() => {
    const query = parseQueryString(location.search);

    const utmQueryValue = query.UTM as string;
    const utmValue = sessionStorage.getItem('utmValue');

    if (!utmQueryValue && utmValue) {
      const searchParams = new URLSearchParams(location.search);

      searchParams.append('UTM', utmValue);

      replace({
        pathname: location.pathname,
        search: searchParams.toString()
      });
    } else if (utmQueryValue && !utmValue) {
      sessionStorage.setItem('utmValue', utmQueryValue);
    }
  }, [location, replace]);

  return;
};
