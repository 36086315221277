import { gql, TypedDocumentNode } from '@apollo/client';

type ResendOtpCodeMutationInputData = {
  type: 'resend_otp';
  attributes: {
    email: string;
    password: string;
  };
};

type ResendOtpCodeMutationInput = {
  data: ResendOtpCodeMutationInputData;
};

type ResendOtpCodeMutation = {
  resend: {
    __typename: 'ResendOtpCodePayload';
  };
};

type ResendOtpCodeMutationVariables = {
  input: ResendOtpCodeMutationInput;
};

export const RESEND_OTP_CODE: TypedDocumentNode<
  ResendOtpCodeMutation,
  ResendOtpCodeMutationVariables
> = gql`
  mutation DisableTwoFactorAuthSmsMutation($input: ResendOtpCodeMutationInput) {
    resend(input: $input)
      @rest(
        type: "ResendOtpCodePayload"
        endpoint: "user"
        method: "POST"
        path: "/v2/resend_otp"
      ) {
      NoResponse
    }
  }
`;
