import { useMemo } from 'react';
import { filter } from 'ramda';
import {
  DesktopNavigatorVerticalFragment,
  DesktopNavigatorVerticalsQuery,
  DesktopNavigatorVerticalsQueryVariables,
  NavigatorPlatform,
  NavigatorVertical
} from '../../generated/types';
import { useSkippableQuery } from '../useSkippableQuery';
import { useDesktopNavigationV2FeatureFlag } from '../useDesktopNavigationV2FeatureFlag';
import { DESKTOP_NAVIGATOR_VERTICALS_QUERY } from './desktopNavigatorQuery.gql';

const isDesktopNavigator = (
  navigator: Pick<NavigatorVertical, '__typename'>
): navigator is DesktopNavigatorVerticalFragment =>
  navigator.__typename === 'DesktopNavigatorVertical';

export const useDesktopNavigatorQuery = (skip?: boolean) => {
  const { data, loading, error } = useSkippableQuery<
    DesktopNavigatorVerticalsQuery,
    DesktopNavigatorVerticalsQueryVariables
  >(DESKTOP_NAVIGATOR_VERTICALS_QUERY, {
    variables: {
      platform: NavigatorPlatform.DESKTOP
    },
    skip
  });

  const verticals = useMemo(() => {
    if (!data) return;

    return filter(isDesktopNavigator, data.navigatorVerticals);
  }, [data]);

  return {
    data: verticals,
    loading,
    error
  };
};

export const useDesktopNavigator = () => {
  const isDesktopSiteNavV2Enabled = useDesktopNavigationV2FeatureFlag();

  const { data, loading, error } = useDesktopNavigatorQuery(!isDesktopSiteNavV2Enabled);

  return {
    data,
    loading,
    error,
    isDesktopSiteNavV2Enabled
  };
};
