import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  TwoFactorAuthMethod,
  VERIFY_ONE_TIME_PASSWORD_MUTATION
} from './verifyOneTimePassword.rest';

export const useVerifyOneTimePassword = () => {
  const [verify, { loading, error }] = useMutation(VERIFY_ONE_TIME_PASSWORD_MUTATION);

  const verifyCode = useCallback(
    (otpCode: string, method: TwoFactorAuthMethod, email: string, password: string) =>
      verify({
        variables: {
          input: {
            data: {
              type: 'two_factor_auth',
              attributes: {
                email,
                password,
                otp_code: otpCode,
                two_factor_auth_method: method
              }
            }
          }
        }
      }),
    [verify]
  );

  return {
    verifyCode,
    loading,
    error
  };
};
