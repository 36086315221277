import { gql, TypedDocumentNode } from '@apollo/client';
import React, { useCallback, useReducer, useRef } from 'react';
import classNames from 'classnames';
import { SearchInput } from '@moda/om';
import { useHistory } from 'react-router-dom';
import { useClickOutside, useDebounce } from '@moda/portal-stanchions';
import { QuickSearchQuery, QuickSearchQueryVariables } from '../../generated/types';
import { useSkippableQuery } from '../../hooks/useSkippableQuery';
import { useDesktopNavigator } from '../../hooks/useNavigator';
import { parseSearchResults } from './parseSearchResults';
import { QuickSearchResults } from './QuickSearchResults';

import './QuickSearch.scss';

const SEARCH_ICON_SIZE_V2 = 24;

interface Props extends React.FormHTMLAttributes<HTMLFormElement> {
  placeholder?: string;
  mobile?: boolean;
  onSelect?: () => void;
  noBorders?: boolean;
}

interface State {
  query: string;
  open: boolean;
  cursor: number;
}

type Action =
  | { type: 'CLEAR_QUERY' }
  | { type: 'UPDATE_QUERY'; payload: { query: string } }
  | { type: 'CLOSE_RESULTS' }
  | { type: 'OPEN_RESULTS' };

export const QUICK_SEARCH_QUERY: TypedDocumentNode<QuickSearchQuery, QuickSearchQueryVariables> =
  gql`
    query QuickSearchQuery($query: String, $limit: Int) {
      autocomplete(q: $query, limit: $limit) {
        trunkshows: trunkshow_autocomplete_facets {
          name
          slug
          season
          gender
        }
        designers: designer_name_slugs {
          name
          slug
          genderSlug: gender_slug
        }
        categories: category_pages {
          gender
          category
          subcategory
          attribute
          name
        }
      }
      search(input: { q: $query }) {
        variants(first: $limit) {
          edges {
            node {
              id
              name
              slug
              gender
              designerSlug: designer_slug
            }
          }
        }
      }
    }
  `;

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'CLEAR_QUERY':
      return { ...state, query: '', open: false };
    case 'UPDATE_QUERY':
      return { ...state, query: action.payload.query };
    case 'CLOSE_RESULTS':
      return { ...state, open: false };
    case 'OPEN_RESULTS':
      return { ...state, open: true };
  }
};

const DEBOUNCE_AMOUNT_MS = 50;
const LIMIT = 50;

export const QuickSearch: React.FC<Props> = ({
  className,
  mobile,
  onSelect,
  placeholder = 'Search',
  noBorders,
  ...rest
}) => {
  const { isDesktopSiteNavV2Enabled } = useDesktopNavigator();
  const [state, dispatch] = useReducer(reducer, {
    query: '',
    cursor: 0,
    open: false
  });
  const history = useHistory();
  const debouncedQuery = useDebounce(state.query, DEBOUNCE_AMOUNT_MS);
  const { data, loading, error } = useSkippableQuery(QUICK_SEARCH_QUERY, {
    variables: { query: debouncedQuery, limit: LIMIT },
    skip: !state.query
  });

  const { searchResults, searchResultSets } = parseSearchResults({
    query: debouncedQuery,
    data,
    showBeauty: true
  });

  const handleEnter = useCallback(
    () => history.push(`/search?q=${state.query}`),
    [history, state.query]
  );
  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      dispatch({ type: 'CLEAR_QUERY' });
      handleEnter();
    },
    [handleEnter]
  );
  const handleClose = useCallback(() => dispatch({ type: 'CLOSE_RESULTS' }), []);
  const handleChangeValue = useCallback(
    (query: string) => {
      dispatch({ type: 'UPDATE_QUERY', payload: { query } });
      if (query === '') {
        handleClose();
      } else {
        dispatch({ type: 'OPEN_RESULTS' });
      }
    },
    [handleClose]
  );
  const handleKeyboardEnter = useCallback(
    (href: string, interactive: boolean) => {
      if (interactive) {
        history.push(href);
        dispatch({ type: 'CLEAR_QUERY' });
      } else {
        handleEnter();
      }
    },
    [history, handleEnter]
  );
  const ref = useRef<HTMLFormElement>(null);

  useClickOutside(ref, handleClose);

  return (
    <form
      className={classNames('QuickSearch', {
        'QuickSearch--mobile': mobile
      })}
      role="search"
      method="get"
      action="/search"
      onSubmit={handleSubmit}
      ref={ref}
      {...rest}
    >
      <SearchInput
        onChange={handleChangeValue}
        className={classNames('QuickSearch', className)}
        placeholder={placeholder}
        value={state.query}
        data-testid="search"
        noBorders={noBorders}
        searchIconSize={isDesktopSiteNavV2Enabled ? SEARCH_ICON_SIZE_V2 : undefined}
      />

      {state.open && (
        <div className="QuickSearch__results" data-testid="results">
          <QuickSearchResults
            mobile={mobile}
            query={state.query}
            loading={loading}
            error={error}
            results={searchResults}
            resultSets={searchResultSets}
            onEnter={handleKeyboardEnter}
            onClose={handleClose}
            onSelect={onSelect}
          />
        </div>
      )}
    </form>
  );
};
