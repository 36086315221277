import React, { useEffect, useState } from 'react';
import FavoriteOutlineIcon from '@moda/icons/favorite-outline-24';
import loadable from '@loadable/component';
import { isClientSide } from '@moda/portal-stanchions';
import { Link } from '../../../Link';
import { QuickSearch } from '../../../QuickSearch';
import { MiniCart } from '../../../MiniCart';
import { hrefFor } from '../../../../routers';
import { useShowAdminModeToggle } from '../../../AdminMode/useShowAdminModeToggle';
import {
  SitePromoBannerLinkSignedIn,
  SitePromoBannerLinkSignedOut
} from '../../../SitePromoBanner/SitePromoBannerLink';
import { useUser } from '../../../../hooks/useUser';
import { useDesktopNavigator } from '../../../../hooks/useNavigator';

import './DesktopSiteNavActions.scss';

const AdminModeTriggerer = loadable(() => import('../../../AdminMode/AdminModeTriggerer'));

export const DesktopSiteNavActions: React.FC = () => {
  const showAdminMode = useShowAdminModeToggle();
  const [hiddenByRequest, setHiddenByRequest] = useState(false);
  const { isDesktopSiteNavV2Enabled } = useDesktopNavigator();
  const { user } = useUser();

  const handleKey = (event: KeyboardEvent) => {
    if (event.ctrlKey && event.shiftKey && event.key === '0') {
      setHiddenByRequest(previously => !previously);
    }
  };

  useEffect(() => {
    if (isClientSide()) {
      window.addEventListener('keydown', handleKey);
    }
    return () => {
      if (isClientSide()) {
        window.removeEventListener('keydown', handleKey);
      }
    };
  }, []);

  if (isDesktopSiteNavV2Enabled) {
    return (
      <div className="DesktopSiteNavActions">
        <QuickSearch noBorders className="DesktopSiteNavActions__search" />

        {user.isLoggedIn && <SitePromoBannerLinkSignedIn firstName={user.firstName} />}

        {!user.isLoggedIn && <SitePromoBannerLinkSignedOut />}

        <Link
          className="DesktopSiteNavActions__favorite"
          to={hrefFor.FavoritesPage()}
          aria-label="My Favorites"
        >
          <FavoriteOutlineIcon />
        </Link>

        <MiniCart />

        {!hiddenByRequest && showAdminMode && <AdminModeTriggerer />}
      </div>
    );
  }

  return (
    <div className="DesktopSiteNavActions">
      <QuickSearch className="DesktopSiteNavActions__search" />

      <Link
        className="DesktopSiteNavActions__favorite"
        to={hrefFor.FavoritesPage()}
        aria-label="My Favorites"
      >
        <FavoriteOutlineIcon />
      </Link>

      <MiniCart />

      {!hiddenByRequest && showAdminMode && <AdminModeTriggerer />}
    </div>
  );
};
