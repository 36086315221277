import React, { StrictMode } from 'react';
import loadable from '@loadable/component';
import { Helmet } from 'react-helmet-async';
import { Router } from './routers';
import { CookiesProvider } from './hooks/useCookies';
import { LandingPageForRussiaProvider } from './pages/LandingPageForRussia';
import { ScrollToTop } from './components/ScrollToTop';
import { ReloadOnResetCache } from './components/ReloadOnResetCache';
import { ErrorModal } from './components/ErrorModal';
import { Loading } from './components/Loading';
import { DialogProvider } from './components/Dialog';
import { FavoritesProvider } from './components/Favorite/FavoritesProvider';
import { ApplePayProvider } from './components/ApplePay';
import { AuthenticationModal, AuthModalProvider } from './components/Authentication';
import { AdminModeProvider } from './components/AdminMode/AdminModeProvider';
import { TwoFactorAuthenticationProvider } from './components/Authentication/TwoFactorAuthenticationProvider';
import { EmailVerificationStatusModalProvider } from './components/EmailVerificationStatus';

import './App.scss';

const ErrorPage = loadable(() => import('./pages/ErrorPage'), {
  fallback: <Loading />
});

type Props = {
  error?: boolean;
};

export const App = ({ error }: Props) => (
  <StrictMode>
    <ReloadOnResetCache>
      <ErrorModal />

      <ScrollToTop />

      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0" />
      </Helmet>

      <div className="App">
        <AuthModalProvider>
          <TwoFactorAuthenticationProvider>
            <CookiesProvider>
              <FavoritesProvider>
                <DialogProvider>
                  <ApplePayProvider>
                    <LandingPageForRussiaProvider>
                      <AdminModeProvider>
                        <EmailVerificationStatusModalProvider>
                          <AuthenticationModal />

                          {error ? <ErrorPage /> : <Router />}
                        </EmailVerificationStatusModalProvider>
                      </AdminModeProvider>
                    </LandingPageForRussiaProvider>
                  </ApplePayProvider>
                </DialogProvider>
              </FavoritesProvider>
            </CookiesProvider>
          </TwoFactorAuthenticationProvider>
        </AuthModalProvider>
      </div>
    </ReloadOnResetCache>
  </StrictMode>
);
