import React from 'react';
import { Clickable, Popover, Stack, Text } from '@moda/om';
import classNames from 'classnames';
import AccountIcon from '@moda/icons/account-24';
import { hrefFor } from '../../routers';
import { Z_INDEXES } from '../../generated/zIndexes';
import { useUser } from '../../hooks/useUser';
import { useAuthModal } from '../../hooks/useAuthModal';
import { AccountLinks } from '../AccountLinks';
import { Link } from '../Link';
import { useEnableClubModaTreatment } from '../../hooks/useEnableClubModaTreatment';
import { useDesktopNavigator } from '../../hooks/useNavigator';

import './SitePromoBannerLink.scss';

const MAX_CHARACTERS = 10;

const truncateName = (name: string) =>
  name.length > MAX_CHARACTERS ? `${name.substring(0, MAX_CHARACTERS)}...` : name;

export const SitePromoBannerLinkSignedOut: React.FC = () => {
  const { isDesktopSiteNavV2Enabled } = useDesktopNavigator();
  const { openAuthModal } = useAuthModal();
  const enableClubModaTreatment = useEnableClubModaTreatment();

  return (
    <Clickable
      className={classNames('SitePromoBannerLink', {
        'SitePromoBannerLink--club': enableClubModaTreatment
      })}
      data-test-id="sign-in-button"
      aria-label="Sign In"
      onClick={() => openAuthModal()}
    >
      {isDesktopSiteNavV2Enabled && <AccountIcon height="24px" width="24px" />}

      {!isDesktopSiteNavV2Enabled && <Text>Sign In</Text>}
    </Clickable>
  );
};

export const SitePromoBannerLinkSignedIn: React.FC<{ firstName: string | null }> = ({
  firstName
}) => {
  const enableClubModaTreatment = useEnableClubModaTreatment();

  const { isDesktopSiteNavV2Enabled } = useDesktopNavigator();

  if (isDesktopSiteNavV2Enabled) {
    return (
      <Popover
        className="SitePromoBannerLink__popover"
        zIndex={Z_INDEXES['site-promo-banner-popover']}
        content={<AccountLinks />}
        anchor="topRight"
      >
        <Stack space={1} direction="horizontal" alignItems="center">
          <AccountIcon height="24px" width="24px" />

          <Link
            className={classNames('SitePromoBannerLink', {
              'SitePromoBannerLink--club': enableClubModaTreatment,
              'SitePromoBannerLink--nav-v2': isDesktopSiteNavV2Enabled
            })}
            to="/account"
          >
            {firstName && (
              <div className="SitePromoBannerLink__welcoming-message">
                <Text treatment="body2" className="SitePromoBannerLink__greetings-top">
                  Hi,
                </Text>

                <Text treatment="body2" className="SitePromoBannerLink__greetings-bottom">
                  {truncateName(firstName)}
                </Text>
              </div>
            )}

            {!firstName && 'Welcome!'}
          </Link>
        </Stack>
      </Popover>
    );
  }

  return (
    <Popover
      className="SitePromoBannerLink__popover"
      zIndex={Z_INDEXES['site-promo-banner-popover']}
      content={<AccountLinks />}
      anchor="topRight"
    >
      <Link
        className={classNames('SitePromoBannerLink', {
          'SitePromoBannerLink--club': enableClubModaTreatment
        })}
        to="/account"
      >
        {firstName ? `Hi, ${firstName}` : 'Welcome!'}
      </Link>
    </Popover>
  );
};

export const SitePromoBannerLink: React.FC = () => {
  const { user } = useUser();
  const { isDesktopSiteNavV2Enabled } = useDesktopNavigator();

  if (isDesktopSiteNavV2Enabled) {
    return (
      <Stack space={1} direction="horizontal" justifyContent="flex-end">
        <Clickable
          className={classNames('SitePromoBannerLink', 'SitePromoBannerLink__left', {
            'SitePromoBannerLink--nav-v2': isDesktopSiteNavV2Enabled
          })}
          to={hrefFor.ModaPrivate()}
        >
          Need a Stylist?
        </Clickable>
      </Stack>
    );
  }

  if (user.isLoggedIn) {
    return <SitePromoBannerLinkSignedIn firstName={user.firstName} />;
  }

  return <SitePromoBannerLinkSignedOut />;
};
